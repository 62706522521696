import {
	type RequestServiceOptions,
	type ServiceConfig,
	utils,
} from '@atlaskit/util-service-support';

import { timed } from '../utils';

import { type ScopedAggregatorResponse } from './aggregator-types';
import {
	type AllSupportedResponseAndScopes,
	type ModelParam,
	type Scope,
	type SearchAnalyticParams,
	type SearchContext,
	type Site,
} from './types';

const MAX_SITES_INITIAL_QUERY = 3;

const limitToMaxSiteUsage = (sites: Site[] = []): Site[] => {
	return sites.slice(0, MAX_SITES_INITIAL_QUERY);
};

type AutoCorrectionResponse = {
	fullCorrection?: string;
	termCorrections?: {
		count: number;
		distance: number;
		score: number;
		term: string;
	}[];
};

interface BaseClientSearchParams<SearchScopes, SupportedFilters> {
	query: string;
	context: SearchContext;
	scopes: SearchScopes;
	modelParams: ModelParam[];
	resultLimit?: number;
	filters?: SupportedFilters[];
	experience: string;
	/**
	 * A list of cloud ids to search. If this is empty we will use the default `cloudId` that is used to construct this client to perform the search.
	 */
	sites?: string[];
}

// Type used to help unpack the types that make up an array
type Unpack<A> = A extends Array<infer E> ? E : A;
// Type used to lookup the response based on the scope
type ResponseFromScope<S, T> =
	S extends ScopedAggregatorResponse<infer E> ? (T extends E ? S : never) : never;

interface MultiscopeSearchResponse<T> {
	scopes: T[];
}

export interface SearchResponse<
	Scope,
	Response extends ScopedAggregatorResponse<Scope>,
	SearchScope,
> {
	rawData: MultiscopeSearchResponse<ResponseFromScope<Response, Unpack<SearchScope>>>;
	retrieveScope<SingleScope extends Unpack<SearchScope>>(
		scope: SingleScope,
	): ResponseFromScope<Response, SingleScope> | null;
}

export interface SearchResponseWithTiming<S, R extends ScopedAggregatorResponse<S>, SR> {
	response: SearchResponse<S, R, SR>;
	requestDurationMs: number;
}

export interface AggregatorConfig {
	cloudId: string;
	siteMasterList: Site[];
	url: string;
	useGetForScopesAPI?: boolean;
}

interface SearchParams<SearchScopes extends Scope[]> {
	/**
	 * The query to search for.
	 */
	query: string;
	/**
	 * The types of entities to search for.
	 */
	scopes: SearchScopes;
	/**
	 * Meta information for analytic purposes.
	 */
	analytics: SearchAnalyticParams;
	/**
	 * The number of results to find, this applies to every scope that is being searched for.
	 */
	resultLimit?: number;
	/**
	 * The list of cloud ids to search. Overrides AggregatorClient.siteMasterList if provided.
	 */
	sites?: string[];
}

export interface SearchClient {
	getAutoCorrection({ query }: { query: string }): Promise<string | undefined>;
	search<SearchScopes extends Scope[]>(
		params: SearchParams<SearchScopes>,
	): Promise<SearchResponseWithTiming<Scope, AllSupportedResponseAndScopes, SearchScopes>>;
}

export type ServerProduct = 'jira' | 'confluence';

class AggregatorClient<
	Resp extends ScopedAggregatorResponse<Scope>,
	SupportedFilters,
	Scope = Resp['id'],
> {
	private serviceConfig: ServiceConfig;

	private cloudId: string;

	private siteMasterList: Site[];

	private static readonly QUICKSEARCH_API_URL = 'quicksearch/v1';

	private static readonly AUTO_CORRECT_API_URL = 'autocorrect';

	constructor({ url, cloudId, siteMasterList }: AggregatorConfig) {
		this.serviceConfig = { url: url };
		this.cloudId = cloudId;
		this.siteMasterList = siteMasterList;
	}

	public async getAutoCorrection({ query }: { query: string }): Promise<string | undefined> {
		const cloudId = this.cloudId;

		// The language is hardcoded to 'en' as the backend does not support other languages
		// Also the frontend only auto corrects in English locale
		const body = {
			query,
			language: 'en',
			cloudId,
		};

		const response = await this.makePostRequest<AutoCorrectionResponse>(
			AggregatorClient.AUTO_CORRECT_API_URL,
			body,
		);

		return response.fullCorrection;
	}

	public async search<SearchScopes extends Scope[]>({
		query,
		context,
		scopes,
		modelParams,
		resultLimit,
		filters = [],
		experience,
		sites = [],
	}: BaseClientSearchParams<SearchScopes, SupportedFilters>): Promise<
		SearchResponseWithTiming<Scope, Resp, SearchScopes>
	> {
		const cloudId = this.cloudId;
		const cloudIdsToFilterOn =
			sites.length > 0
				? sites
				: limitToMaxSiteUsage(this.siteMasterList).map((site) => site.cloudId);
		const body = {
			query: query,
			cloudId,
			limit: resultLimit,
			scopes,
			filters: filters,
			searchSession: context,
			...(modelParams.length > 0 ? { modelParams } : {}),
			experience,
			cloudIds: cloudIdsToFilterOn,
		};

		const { result: response, durationMs: requestDurationMs } = await timed(
			this.makePostRequest<MultiscopeSearchResponse<ResponseFromScope<Resp, Unpack<SearchScopes>>>>(
				AggregatorClient.QUICKSEARCH_API_URL,
				body,
			),
		);

		return {
			response: {
				rawData: response,
				retrieveScope: (scope) =>
					// @ts-expect-error TS2367: This comparison appears to be unintentional because the types 'Scope' and 'SingleScope' have no overlap
					response.scopes.find((s) => s.id === scope) || null,
			},
			requestDurationMs,
		};
	}

	private makePostRequest = <T,>(path: string, body: object): Promise<T> => {
		const options: RequestServiceOptions = {
			path,
			requestInit: {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			},
		};
		return utils.requestService<T>(this.serviceConfig, options);
	};
}

export interface Config {
	/**
	 * The cloud id of the instance you're searching for
	 */
	cloudId: string;
	/**
	 * The URL to the search backend (xpsearch-aggregator).
	 * This is entirely optional and is used to override the default URL when you need to make a request to a specific xpsearch-aggregator.
	 */
	aggregatorUrl?: string;
}

/**
 * Creates a search client.
 *
 * This operation is considered expensive and it's recommended that the resulting client is reused.
 */
export const createClient: (config: Config) => SearchClient = ({ cloudId, aggregatorUrl }) => {
	const aggregatorClient: AggregatorClient<AllSupportedResponseAndScopes, []> =
		new AggregatorClient({
			cloudId,
			url: aggregatorUrl || '/gateway/api/xpsearch-aggregator',
			siteMasterList: [],
		});

	async function getAutoCorrection({ query }: { query: string }) {
		return aggregatorClient.getAutoCorrection({ query });
	}

	async function search<SearchScopes extends Scope[]>({
		query,
		scopes,
		resultLimit,
		analytics,
		sites = [],
	}: SearchParams<SearchScopes>) {
		return aggregatorClient.search<SearchScopes>({
			query,
			context: {
				sessionId: analytics.sessionId ? analytics.sessionId : 'not-applicable',
				referrerId: null,
			},
			scopes,
			modelParams: [],
			resultLimit,
			filters: [],
			experience: `external-${analytics.identifier}`,
			sites,
		});
	}

	return {
		search,
		getAutoCorrection,
	};
};
